import { render, staticRenderFns } from "./WhatWeDoFocusAreas.vue?vue&type=template&id=31839eb4&scoped=true"
import script from "./WhatWeDoFocusAreas.vue?vue&type=script&lang=js"
export * from "./WhatWeDoFocusAreas.vue?vue&type=script&lang=js"
import style0 from "./WhatWeDoFocusAreas.vue?vue&type=style&index=0&id=31839eb4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31839eb4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsHeader: require('/src/components/organisms/Header.vue').default,OrganismsModules: require('/src/components/organisms/Modules.vue').default,OrganismsFooter: require('/src/components/organisms/Footer.vue').default})
