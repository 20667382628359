
import { arrayOf, object } from 'vue-types';
import modulesMixin from '@/mixins/modules';
import { COLOR_SCHEME_LIGHT, SIZE_MEDIUM } from '~/constants/General';

export default {
  mixins: [modulesMixin],
  inheritAttrs: false,
  props: {
    header: arrayOf(object()),
    modules: arrayOf(object()),
  },
  data() {
    return { COLOR_SCHEME_LIGHT, SIZE_MEDIUM };
  },
  computed: {
    navLabels() {
      return [
        {
          label: this.$t('pages.whatWeDo'),
          to: this.localePath({
            name: 'whatWeDo',
          }),
        },
      ];
    },
  },
};
